const academy = [
    {
        id: 1,
        nome: "Suzano - SP",
        latitude: -23.5445969,
        longitude: -46.3166406,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/5/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/5/site/IM%5BBAR%5DSdyZjMVwgVnRgKt6b9g%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 2,
        nome: "Viamão - RS",
        latitude: -30.0824005,
        longitude: -51.0199456,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/18/totem'
        //frame: 'https://evo-totem.w12app.com.br/allpfit/18/site/i681zQSE0wGa4tBjj0CKyQ%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 3,
        nome: "Vila Carrão",
        latitude: -23.5508267,
        longitude: -46.5323575,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/2/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/2/site/vYRj9eMXLQ5dTEH7%5BPLUS%5DR42dg%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 4,
        nome: "Vila Sônia",
        latitude: -23.6065263,
        longitude: -46.7486925,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/17/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/17/site/CRqyw8jOrfq3iiY4euLoCg%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 5,
        nome: "Vitória da Conquista - BA",
        latitude: -14.8511123,
        longitude: -40.8484733,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/26/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/26/site/qay%5BPLUS%5D%5BBAR%5DQQGYuqK0fqpvx50Ug%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 6,
        nome: "Aricanduva",
        latitude: -23.5792123,
        longitude: -46.5025775,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/17/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/17/site/CRqyw8jOrfq3iiY4euLoCg%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 7,
        nome: "Ferraz de Vasconcelos - SP",
        latitude: -23.5426519,
        longitude: -46.3695573,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/1/totem'  //TODO: Alterar para o valor correto
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/1/totem'
    },
    {
        id: 8,
        nome: "Grajaú",
        latitude: -23.7678769,
        longitude: -46.6957218,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/8/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/8/site/82pusjWxF6YoZfqAbFmy5A%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 9,
        nome: "Ipatinga - MG",
        latitude: -19.4635345,
        longitude: -42.5619563,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/13/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/13/site/Hv4bY2JnGwWmsXXwsihPng%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 10,
        nome: "Itabuna - BA",
        latitude: -14.8061763,
        longitude: -39.2753881,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/28/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/28/site/1MLti1L3K82KO%5BPLUS%5D6KmFIKEA%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 11,
        nome: "Itaim I",
        latitude: -23.531069256809907, //, -
        longitude: -46.434801589909966,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/1/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/1/totem'
    },
    {
        id: 12,
        nome: "Itaim II",
        latitude: -23.4992751,
        longitude: -46.3943451,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/21/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/21/site/qFGpuVG2CYkGZVajHu57Ow%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 14,
        nome: "Londrina - PR",
        latitude: -23.3135507,
        longitude: -51.1532605,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/27/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/27/site/0ZADiQaSEq2047LTydZa5g%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 15,
        nome: "Mogi das Cruzes - SP",
        latitude: -23.5414805,
        longitude: -46.2015420,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/10/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/10/site/M%5BPLUS%5DmJonkIFxSlspEsVxOJig%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 16,
        nome: "Poá - SP",
        latitude: -23.5181343,
        longitude: -46.3418455,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/11/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/11/site/%5BBAR%5DPL1bvPx%5BBAR%5D9kD%5BPLUS%5DF1Oxv6EJQ%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 17,
        nome: "Porto Alegre - RS",
        latitude: -29.9955074,
        longitude: -51.1303178,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/16/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/16/site/0wlEumjXPKErALWwGIxp7Q%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 18,
        nome: "Praia Grande - SP",
        latitude: -24.0068988,
        longitude: -46.4282761,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/9/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/9/site/lcc5uVkm%5BPLUS%5Dk1qSfJJ3Nqb4Q%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 19,
        nome: "Santana de Parnaíba - SP",
        latitude: -23.4217707,
        longitude: -46.8914281,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/15/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/15/site/LGMf%5BPLUS%5DKZEv45fqnoSVjrZOQ%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 20,
        nome: "São Caetano do Sul - SP",
        latitude: -23.6435664,
        longitude: -46.5742492,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/12/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/12/site/z%5BPLUS%5DfhkYSrzBrcwyuxdTS8xQ%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 21,
        nome: "São José do Rio Preto - SP",
        latitude: -20.7678281,
        longitude: -49.3850981,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/14/totem'
        //frame: 'https://evo-totem.w12app.com.br/allpfit/14/site/KaChlzjBrSjf2LC20MP8Yg%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 22,
        nome: 'Sorocaba - SP',
        latitude: -23.5230890,
        longitude: -47.4910365,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/29/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/29/site/ixNnMrON8x6zInt2N0%5BPLUS%5Dwiw%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 23,
        nome: 'Shopping Vale do Aço',
        latitude: -19.5093955,
        longitude: -42.5767277,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/25/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/25/site/8VGMoOnBLSKafcLuXHBygA%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 24,
        nome: 'Coronel Fabriciano',
        latitude: -19.5179,
        longitude: -42.6276,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/24/totem'
        //iframe: 'https://evo-totem.w12app.com.br/allpfit/24/site/e5OuNNWVeqISSbt%5BBAR%5Dd9JpHw%5BEQUAL%5D%5BEQUAL%5D'
    },
    {
        id: 36,
        nome: 'Vila Zeferina',
        latitude: -23.498916822570997,
        longitude: -46.3494724320995,
        iframe: 'https://evo-totem.w12app.com.br/allpfit/36/totem'
    }
    ];
    
    export default academy;