// Home.js
import React, { useState, useEffect } from 'react';
import Launcher from './Launcher';
import '../css/Home.css';
import { useNavigate } from "react-router-dom";
import academy from '../Helpers/stateCode';

const Home = ({ items }) => {
  const [isScreensaverActive, setScreensaverActive] = useState(false);
  const [academiaMaisProxima, setAcademiaMaisProxima] = useState(null);

  function GetLocation(){
    navigator.geolocation.getCurrentPosition(
      position => {
        let { latitude, longitude } = position.coords;
        let menorDistancia = Infinity;
        let academyNearby = null;

        academy.forEach(academia => {
          const rEarth = 6371;
          const dLat = (academia.latitude - latitude) * (Math.PI / 180);
          const dLon = (academia.longitude - longitude) * (Math.PI / 180);
          const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(latitude * (Math.PI / 180)) * Math.cos(academia.latitude * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          let distancia = rEarth * c;

          if (distancia < menorDistancia) {
            menorDistancia = distancia;
            academyNearby = academia;
          }
        });

        setAcademiaMaisProxima(academyNearby);
      },
      error => {
        console.error("Erro ao obter a localização", error);
      }
    );
  }

  useEffect(() => {
    let timeout;
    document.title = 'Allp Fit';
    GetLocation();

    const resetTimer = () => {
      clearTimeout(timeout);
      setScreensaverActive(false);
      timeout = setTimeout(() => setScreensaverActive(true), 720000); // 720000 ms = 10 minutos
    };

    const handleUserActivity = () => resetTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    resetTimer();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
    };
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/planos');
  };

  return isScreensaverActive ? (
    <Launcher />
  ) : (
    <div className="home-page">
      <header className="header">
        <div className="header-container">
          <img fetchpriority="high" decoding="async" width="250" height="60" src="https://allpfit.com.br/wp-content/webp-express/webp-images/uploads/2023/06/logo-intro-1-1024x253.png.webp" alt="Allpfit Logo" />
        </div>
      </header>
      <div className="main">
        <div className="container">
          {
            academiaMaisProxima ? (
              <div className="main-text">Seja bem-vindo (a) <br></br> {academiaMaisProxima.nome} </div>
            ) : (
              <div className="main-text">Seja bem-vindo (a) AllpFit </div>
            )
          }
          
          <div className="sub-text">Toque no botão para iniciar o seu Cadastro</div>
          <button className="start-button" onClick={handleBack}>INICIAR</button>
        </div>
      </div>
    </div>
  );
}

export default Home;
